<template>
	<draggable v-model="statuses" class="layout column" handle=".workflow-handle" style="min-width:100%">
		<v-layout v-for="(status, index) in statuses" :key="index" row>
			<v-layout v-if="!readonly" column justify-center px-1 shrink>
				<v-flex class="pointer workflow-handle" shrink>
					<v-icon>drag_indicator</v-icon>
				</v-flex>
			</v-layout>
			<v-layout column justify-center shrink>
				<v-flex shrink>
					<w-color-picker v-model="status.color" :readonly="readonly" :size="width < 600 ? '28px' : '36px'" />
				</v-flex>
			</v-layout>
			<v-layout column justify-center px-1 shrink>
				<v-flex shrink>
					<IconInput v-model="status.icon" :readonly="readonly" required :small="width < 600" />
				</v-flex>
			</v-layout>
			<v-flex grow px-1>
				<w-text-input
					v-model="status.name"
					:disabled="readonly"
					:label="$t('workflows.status_name')"
					maxlength="191"
					:readonly="readonly"
					required
				/>
			</v-flex>
			<v-layout v-if="!readonly" column justify-center px-1 shrink>
				<v-flex shrink>
					<w-btn icon="add" mini small @click="addStatus(index)">{{ $t('workflows.actions.add_below') }}</w-btn>
					<w-btn-delete :disabled="statuses.length <= 1" :flat="false" mini small @click="removeStatus(index)" />
				</v-flex>
			</v-layout>
		</v-layout>
	</draggable>
</template>

<script>
const DEFAULT_ICON = 'fas fa-check'

const DEFAULT_STATUS = {
	icon: DEFAULT_ICON,
	color: '#000000',
	name: '',
	users: []
}

export default {
	name: 'WorkflowStatusesInput',
	components: {
		draggable: () => import('vuedraggable'),
		IconInput: () => import('@/components/Commons/Inputs/IconInput'),
	},
	props: {
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: null,
			required: false,
			type: Array
		},
		width: {
			required: true,
			type: Number
		}
	},
	computed: {
		statuses: {
			get: function () {
				if (!Array.isArray(this.value) || this.value.length == 0) {
					return [
						{ ...DEFAULT_STATUS }
					]
				}
				return this.value
			},
			set: function (statuses) {
				this.$emit('input', statuses)
			}
		}
	},
	watch: {
		value: {
			handler: function (value) {
				if (!Array.isArray(value) || value.length == 0) {
					this.$emit('input', [
						{ ...DEFAULT_STATUS }
					])
				}
			}
		}
	},
	mounted: function () {
		if (!Array.isArray(this.value) || this.value.length == 0) {
			this.$emit('input', [
				{ ...DEFAULT_STATUS }
			])
		}
	},
	methods: {
		addStatus: function (index) {
			this.statuses.splice(index + 1, 0, { ...DEFAULT_STATUS })
		},
		async removeStatus (index) {
			const confirmed = await this.$dialog
				.confirm({
					text: this.$t('workflows.actions.delete_status.confirmation_text', { name: this.statuses[index].name }),
					title: this.$t('workflows.actions.delete_status.confirmation_title', { name: this.statuses[index].name }),
					actions: {
						false: this.$t('actions.cancel'),
						true: {
							text: this.$t('actions.delete'),
							color: 'error',
							flat: false,
						}
					}
				})

			if (!confirmed) {
				return
			}

			this.statuses.splice(index, 1)
		}
	}
}
</script>
